import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout'
import { startBasicScene } from '../../basic_scene';
import { colorSchemeArray, getRandomColorFromScheme } from '../../color-picker'
import '../styles.css'

const MiscProjectPage = ({ data }) => {
  React.useEffect(() => {
    if (!document.querySelector('.background-animation'))
      startBasicScene()
  })

  const colorScheme = colorSchemeArray()

  return (
    <Layout pageTitle="Misc Projects">
      <Link to="../">
        <button className="back-button">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
        </svg>
        </button>
      </Link>
      {data.allMdx.nodes.map((node, i) => (
        <article className="item" key={node.id} style={{ backgroundColor: colorScheme[i%colorScheme.length] }}>
          <Link to={`/misc/${node.slug}`}>
            <div>
              <h2>{node.frontmatter.title}</h2>
              <p>{node.frontmatter.subtitle}</p>
            </div>
          </Link>
        </article>
      ))}
      {data.allMdx.nodes.length < 1 && (
        <p className="no-items-messages">No items at this time.</p>
      )}
    </Layout>
  );
}

export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { project_type: { glob: "misc" } } }) 
    {
      nodes {
        frontmatter {
          title
          subtitle
        }
        id
        slug
        body
      }
    }
  }
`;

export default MiscProjectPage;